import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import { mq } from '../breakpoints';

const Wrapper = styled.div`
  position: relative;
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.palette.primary.main};
  font-family: 'Tangerine', cursive;
  font-size: 2.5em;
  margin-top: 0;

  ${mq.md(
    css`
      font-size: 4em;
    `
  )}
`;

const StyledArticle = styled.div`
  color: ${(props) => props.theme.palette.secondary.dark};

  h2 {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 1.25em;
  }

  h3 {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 1em;
  }

  strong {
    color: ${(props) => props.theme.palette.primary.light};
  }
`;
const img = css`
  border-radius: 12px;
  float: right;
  height: 120px;
  width: 120px;

  ${mq.sm(css`
    height: 200px;
    margin-left: 8px;
    width: 200px;
  `)}
`;

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulBlog;

    return (
      <Layout pageName='Blog'>
        <H1 className='section-headline'>{post.title}</H1>
        <Wrapper>
          {post.heroImage && <GatsbyImage css={img} alt={post.title} image={post.heroImage.gatsbyImageData} />}
          <StyledArticle
            dangerouslySetInnerHTML={{
              __html: post.teaser.childMarkdownRemark.html,
            }}
          />
          <StyledArticle
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </Wrapper>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      teaser {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData
      }
    }
  }
`;
